html {
	height: 100%;
	display: grid;
	place-items: center;
	background: #f8f8f8;
	color: #1a1a1a;

	@media (prefers-color-scheme: dark) {
		background: #1a1a1a;
		color: #f8f8f8;
	}
}

body {
	margin: 20px 20px 60px;
	font: 300 16px/1.5 "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

h1 {
	font-size: 2em;
	font-weight: 700;
	line-height: 1.2;
	margin: 0 0 0 -1px; // Visual alignment
}

ul {
	display: flex;
	list-style: none;
	font-size: .75em;
	font-weight: 600;
	line-height: 1.2;
	margin-block: 4px 0;
	padding: 0;
	text-transform: uppercase;

	&:has(:empty) li {
		animation-play-state: paused;
	}

	li:not(:empty) + li:before {
		content: '•';
		font-weight: 500;
		padding-inline: .5em;
	}

	li {
		animation: fade-in 0.3s ease-out both;
		animation-delay: calc(0.4s + var(--index) * 0.15s);
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

a {
	--link-color: #05c;

	color: var(--link-color);
	text-decoration: none;

	@media (prefers-color-scheme: dark) {
		--link-color: #{lighten(#05c, 20%)};
	}

	&:focus,
	&:hover {
		text-decoration: underline;
	}

	&.secret {
		color: inherit;

		&:hover {
			color: var(--link-color);
		}
	}
}
